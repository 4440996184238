import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { message } from 'antd'
import { history } from 'index'
import { login, currentAccount, logout, updateUserRole, updateActivity } from 'services/firebase'
import {
  getSuperLogicaUserPayments,
  getSuperLogicaUser,
  getSuperLogicaUserRole,
} from 'services/superlogica'

import startRedriveAudio from '../../assets/startRedriveAudio/start-redrive.mp3'

import actions from './actions'

const language = require('components/ChangeLanguage').getMessage('login')

export const getUserStateField = state => state.user

export function* LOGIN({ payload }) {
  const { email, password } = payload
  /* const {
    intl: { formatMessage },
  } = this.props */

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const playAudio = () => {
    const audio = new Audio(startRedriveAudio)
    audio
      .play()
      .then(() => console.log('Áudio tocando!'))
      .catch(error => console.error('Erro ao reproduzir áudio:', error))
  }

  const loginResult = yield call(login, email, password)
  if (loginResult && loginResult.result) {
    playAudio()
  }
  const role = yield call(getSuperLogicaUserRole, email)

  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
    payload: {
      role,
    },
  })
  if (loginResult && loginResult.result) {
    if (loginResult.passwordChangeNeed) {
      yield history.push('/auth/update-password')
      message.warning({
        content: language.sagas_message_warning_password,
        style: {
          marginTop: '50px',
        },
      })
    } else {
      yield history.push('/')
      message.success({
        content: language.sagas_message_success_login,
        style: {
          marginTop: '50px',
        },
      })
    }
  }
}

export function* FETCH_SUPERLOGICA_USER() {
  const user = yield select(getUserStateField)
  const superLogicaUser = yield call(getSuperLogicaUser, user.email)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      superlogicaFields: superLogicaUser,
    },
  })
}

export function* UPDATE_ACTIVITY() {
  // console.log('UPDATE_ACTIVITY')
  const user = yield select(getUserStateField)
  yield call(updateActivity, user.id)
  // yield put({
  //   type: 'user/UPDATE_ACTIVITY',
  //   payload: {
  //     lastActivityAt: new Date(),
  //   },
  // }
  // )
}
export function* UPDATE_USER({ payload }) {
  // console.log('UPDATE_USER', payload)
  // console.log(user)
  yield put({
    type: 'user/UPDATE_USER',
    payload,
  })
  // yield put({
  //   type: 'user/UPDATE_ACTIVITY',
  //   payload: {
  //     lastActivityAt: new Date(),
  //   },
  // })
}

export function* FETCH_USER_PAYMENTS(action = {}) {
  const { payload: { alert = false, loading = true } = {} } = action
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loadingPayments: loading,
    },
  })
  const user = yield select(getUserStateField)
  const { payments, user: superLogicaUser, waitingPayment } = yield call(
    getSuperLogicaUserPayments,
    user.email,
    alert,
  )
  yield put({
    type: 'user/SET_STATE',
    payload: {
      payments,
      superlogicaFields: superLogicaUser,
      waitingPayment,
      loadingPayments: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT(action = {}) {
  const { payload = {} } = action
  const { role: updatedRole } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { uid: id, email, photoURL: avatar, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: 'Administrator',
        email,
        avatar,
        role: updatedRole || role,
        authorized: true,
      },
    })
    if (updatedRole) {
      updateUserRole(id, updatedRole)
    }
  }

  yield put({
    type: 'user/FETCH_USER_PAYMENTS',
    payload: {
      alert: true,
    },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      loadingPayments: false,
      superlogicaFields: {},
      payments: [],
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.FETCH_USER_PAYMENTS, FETCH_USER_PAYMENTS),
    takeEvery(actions.FETCH_SUPERLOGICA_USER, FETCH_SUPERLOGICA_USER),
    takeEvery(actions.UPDATE_ACTIVITY, UPDATE_ACTIVITY),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
