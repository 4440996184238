import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

import axios from 'axios'
import Crypto from 'services/crypto'
import { setUserAuth } from 'redux/actions'
import { store } from '../../index'
import memoizee from 'memoizee'
import UpdatePassword from 'components/cleanui/system/Auth/UpdatePassword'

const language = require('components/ChangeLanguage').getMessage('services/firebase')

const firebaseConfig = {
  apiKey: 'AIzaSyA7inINbcgTHYrKPb1mEpZ3LIb3dMAzI_k',
  authDomain: 'redrive-site.firebaseapp.com',
  databaseURL: 'https://redrive-site.firebaseio.com',
  projectId: 'redrive-site',
  storageBucket: 'redrive-site.appspot.com',
  messagingSenderId: '480141464173',
  appId: '1:480141464173:web:9d02073b6c839493f02564',
  measurementId: 'G-PPCTBCDYNQ',
}

const firebaseConfigStaging = {
  apiKey: 'AIzaSyBYbl5NnXV26e2hG3p4mk7NPPRUnLAI5hQ',
  authDomain: 'redrive-homologa.firebaseapp.com',
  projectId: 'redrive-homologa',
  storageBucket: 'redrive-homologa-redrive',
  messagingSenderId: '975915114608',
  appId: '1:975915114608:web:ac2b525219385db44a14b5',
}

const maestroBaseEnvironment =
  process.env.REACT_APP_MAESTRO_BUCKET === 'redrive-homologa-redrive'
    ? firebaseConfigStaging
    : firebaseConfig

const firebaseConfigMaestro = {
  ...maestroBaseEnvironment,
  storageBucket: process.env.REACT_APP_MAESTRO_BUCKET,
}

// const firebaseConfigMaestro = {
//   apiKey: 'AIzaSyA7inINbcgTHYrKPb1mEpZ3LIb3dMAzI_k',
//   authDomain: 'redrive-site.firebaseapp.com',
//   databaseURL: 'https://redrive-site.firebaseio.com',
//   projectId: 'redrive-site',
//   storageBucket: 'redrive-maestro',
//   messagingSenderId: '480141464173',
//   appId: '1:480141464173:web:9d02073b6c839493f02564',
//   measurementId: 'G-PPCTBCDYNQ',
// }

// Firebase loga automaticamente na conta ao criar ela no Firebase Auth
// Por isso, criei uma nova instancia do firebase apenas para criacao de contas, assim nao se perde a secao da primeira instancia.
const firebaseSecondApp = firebase.initializeApp(firebaseConfig, 'Secondary')
const firebaseSecondInstance = firebaseSecondApp.auth()
firebase.initializeApp(firebaseConfig)

const firebaseStagingApp = firebase.initializeApp(firebaseConfigStaging, 'Third')
export const storageStaging = firebaseStagingApp.storage()

const firebaseMaestro = firebase.initializeApp(firebaseConfigMaestro, 'Fourth')
export const storageMaestro = firebaseMaestro.storage()

export const firebaseAuth = firebase.auth()
// export const firebaseDatabase = firebase.database()
export const db = firebase.firestore()
export const storage = firebase.storage()
export const firestore = firebase.firestore

let userCache = null

firebaseAuth.onAuthStateChanged(async user => {
  if (user) {
    if (!userCache) {
      const userFields = await db
        .collection('users')
        .doc(user.uid)
        .get()
      let ownerTagsData = []

      if (userFields.data().owner) {
        ownerTagsData = await db
          .collection('users')
          .doc(userFields.data().owner)
          .get()
      }

      const mergedUser = Object.assign(user, {
        id: user.uid,
        name: userFields.data().name,
        role: userFields.data().role,
        owner: userFields.data().owner,
        sms_credits: userFields.data().sms_credits || 0,
        avatar: user.photoUrl,
        tags: userFields.data().owner ? ownerTagsData.data().tags : userFields.data().tags,
        maxConnectionsPerClient: userFields.data().maxConnectionsPerClient || null,
      })

      // console.log('mergedUser', mergedUser)
      userCache = mergedUser
    }
  } else {
    userCache = null
  }

  store.dispatch(setUserAuth(user, userCache))
  // console.log('onAuthStateChanged', user, userCache)
  return userCache
})

function translateErrorsFirebase(error) {
  switch (error.code) {
    case 'auth/weak-password':
      error.message = language.services_firebase_errors_weakPassword
      break
    case 'auth/invalid-email':
      error.message = language.services_firebase_errors_invalidEmail
      break
    case 'auth/operation-not-allowed':
      error.message = language.services_firebase_errors_userDisabled
      break
    case 'auth/email-already-in-use':
      error.message = language.services_firebase_errors_emailInUse
      break
    case 'auth/user-disabled':
      error.message = language.services_firebase_errors_userDisabled
      break
    case 'auth/user-not-found':
      error.message = language.services_firebase_errors_userNotFound
      break
    case 'auth/wrong-password':
      error.message = language.services_firebase_errors_wrongPassword
      break
    case 'auth/web-storage-unsupported':
      error.message = language.services_firebase_errors_webStorageUnsupported
      break
    case 'auth/unauthorized-domain':
    case 'auth/app-not-authorized':
      error.message = language.services_firebase_errors_appNotAuthorized
      break
    case 'auth/too-many-requests':
      error.message = language.services_firebase_errors_tooManyRequests
      break
    case 'auth/network-request-failed':
      error.message = language.services_firebase_errors_networkRequestFailed
      break
    case 'auth/argument-error':
      error.message = language.services_firebase_errors_argumentError
      break
    case 'auth/app-deleted':
      error.message = language.services_firebase_errors_appDeleted
      break
    case 'auth/user-token-expired':
    case 'auth/requires-recent-login':
      error.message = language.services_firebase_errors_requiresRecentLogin
      break
    default:
  }

  notification.warning({
    // message: error.code,
    message: language.services_firebase_notification_warning_title,
    description: error.message,
  })
}

export async function updateUserPassword(newPassword) {
  const user = await currentAccount()

  // console.log(`Updating password: '${user.email}'`)

  user
    .updatePassword(newPassword)
    .then(async () => {
      await db
        .collection('users')
        .doc(user.uid)
        .set({ passwordUpdatedAt: new Date() }, { merge: true })
      return true
    })
    .catch(error => {
      console.log(error)
    })
}

export async function login(email, password) {
  // console.log(`Trying do login with email: '${email}'`)
  console.log('login', email, password)
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(async userCredentials => {
      // Verificamos se existe a necessidade de alterar o password
      const userData = await (
        await db
          .collection('users')
          .doc(userCredentials.user.uid)
          .get()
      ).data()
      const updatePasswordCycleDays = await (
        await db
          .collection('config')
          .doc('limits')
          .get()
      ).data().updatePasswordCycleDays

      let passwordChangeNeed = false

      if (userData !== 'board') {
        if (!userData.passwordUpdatedAt) {
          passwordChangeNeed = true
        } else {
          const date = new Date()
          date.setDate(date.getDate() - updatePasswordCycleDays)

          if (userData.passwordUpdatedAt.toDate && userData.passwordUpdatedAt.toDate() <= date) {
            passwordChangeNeed = true
          }
        }
      }
      console.log('https://us-central1-redrive-site.cloudfunctions.net/login')
      await axios
        .post('https://us-central1-redrive-site.cloudfunctions.net/login', {
          doc: Crypto.encryptWithAES(userCredentials.user.uid),
        })
        .catch(() => {})
      // return { result: true, passwordChangeNeed }
      console.log('login', { result: true, passwordChangeNeed })
      return { result: true, passwordChangeNeed }
    })
    .catch(async error => {
      console.log('error', error)
      // Adicionando notificação de erro
      notification.error({
        message: 'Erro de Login',
        description:
          'Ocorreu um erro ao tentar fazer login. Por favor, verifique seu E-mail/Senha e tente novamente.',
      })
      // salvamos o evento de tentativa de login errado
      const obj = {
        createdAt: new Date(),
        email,
        password,
        firebaseError: error.code,
        userAgent: window.navigator.userAgent,
      }

      const ipify = await axios.get('https://api.ipify.org/?format=json').catch(e => console.log(e))
      if (ipify && ipify.status === 200 && ipify.data && ipify.data.ip) {
        obj.ip = ipify.data.ip

        const ipapi = await axios
          .post('https://us-central1-redrive-site.cloudfunctions.net/getLocation', {
            ip: ipify.data.ip,
          })
          .catch(e => console.log(e))
        if (ipapi && ipapi.status === 200 && ipapi.data) {
          obj.ipLocation = ipapi.data
        }
      }
      await db.collection('login-fail-attempts').add(obj)
    })
}

export async function sendPasswordResetEmail(email) {
  return firebaseAuth.sendPasswordResetEmail(email)
}

export async function register(email, password, name) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        db.collection('users')
          .doc(uid)
          .set(
            {
              role: 'user',
              name,
              email,
            },
            { merge: true },
          )
      }
      return true
    })
    .catch(translateErrorsFirebase)
}

export async function registerCollaborator(
  email,
  password,
  name,
  owner,
  roleType,
  nickname,
  showAttendantName,
) {
  return firebaseSecondInstance
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        // console.log('Conta criada!')

        const obj = {
          role: roleType,
          name,
          email,
          owner,
          showAttendantName,
        }

        if (nickname && nickname.length > 0) {
          obj.nickname = nickname
        }

        db.collection('users')
          .doc(uid)
          .set(obj)

        firebaseSecondApp.auth().signOut()
      }
      return true
    })
    .catch(error => {
      switch (error.code) {
        case 'auth/weak-password':
          error.message = language.services_firebase_errors_weakPassword
          break
        case 'auth/invalid-email':
          error.message = language.services_firebase_errors_invalidEmail
          break
        case 'auth/operation-not-allowed':
          error.message = language.services_firebase_errors_userDisabled
          break
        case 'auth/email-already-in-use':
          error.message = language.services_firebase_errors_emailInUse
          break
        case 'auth/user-disabled':
          error.message = language.services_firebase_errors_userDisabled
          break
        case 'auth/user-not-found':
          error.message = language.services_firebase_errors_userNotFound
          break
        case 'auth/wrong-password':
          error.message = language.services_firebase_errors_wrongPassword
          break
        case 'auth/web-storage-unsupported':
          error.message = language.services_firebase_errors_webStorageUnsupported
          break
        case 'auth/unauthorized-domain':
        case 'auth/app-not-authorized':
          error.message = language.services_firebase_errors_appNotAuthorized
          break
        case 'auth/too-many-requests':
          error.message = language.services_firebase_errors_tooManyRequests
          break
        case 'auth/network-request-failed':
          error.message = language.services_firebase_errors_networkRequestFailed
          break
        case 'auth/argument-error':
          error.message = language.services_firebase_errors_argumentError
          break
        case 'auth/app-deleted':
          error.message = language.services_firebase_errors_appDeleted_2
          break
        case 'auth/user-token-expired':
        case 'auth/requires-recent-login':
          error.message = language.services_firebase_errors_requiresRecentLogin
          break
        default:
      }

      notification.warning({
        // message: error.code,
        message: language.services_firebase_notification_warning_title,
        description: error.message,
      })
    })
}

let lastUpdate = null
export async function updateActivity(id) {
  const now = new Date()
  if (!id) return
  if (!lastUpdate || now.setMinutes(now.getMinutes() - 5) > lastUpdate) {
    // console.log('lastUpdate', id)
    lastUpdate = new Date()
    await db
      .collection('users')
      .doc(id)
      .update({ lastActivityAt: lastUpdate })
  }
}

export async function changeStatusCollaborator(uid, status) {
  // await firebaseAuth.sign
  await firebaseAuth.updateUser(uid, {
    disable: !status,
  })

  const docs = await db
    .collection('users')
    .where('uid', '==', uid)
    .get()

  docs.forEach(async e => {
    await db
      .collection('users')
      .doc(e.id)
      .set({
        enable: status,
      })
  })
}

export async function updateUserRole(userId, role) {
  return db
    .collection('users')
    .doc(userId)
    .set({ role }, { merge: true })
}

export async function getNotifications(userId) {
  return db
    .collection('users')
    .doc(userId)
    .collection('notifications')
    .orderBy('createdAt', 'desc')
    .limit(10)
    .get()
}

const getUserDataById = userUid => {
  return db
    .collection('users')
    .doc(userUid)
    .get()
}

const FIVE_SECONDS_IN_MS = 5 * 1000

const memoizedGetUserDataById = memoizee(getUserDataById, {
  promise: true,
  primitive: true,
  maxAge: FIVE_SECONDS_IN_MS,
})

export async function getUserById(id) {
  console.log('getUserById', id)
  const userFields = await memoizedGetUserDataById(id)
  console.log('userFields', userFields)
  return userFields.data()
}

export async function currentAccount() {
  let userLoaded = false

  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const userFields = await memoizedGetUserDataById(user.uid)

            let ownerTagsData = []

            const data = userFields.data()
            if (data.owner) {
              ownerTagsData = await memoizedGetUserDataById(data.owner)
            }

            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: data.name,
              role: data.role,
              owner: data.owner,
              oldRole: data.oldRole,
              maestro: data.maestro,
              sms_credits: data.sms_credits || 0,
              avatar: user.photoUrl,
              tags: data.owner ? ownerTagsData.data().tags : data.tags,
              maxConnectionsPerClient: data.maxConnectionsPerClient || null,
              showAttendantName: data.showAttendantName || false,
              nickname: data.nickname || null,
            })

            // console.log('mergedUser', mergedUser)
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }

  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}

export function getTimestamp() {
  return firebase.database.ServerValue.TIMESTAMP
}
