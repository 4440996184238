import { notification } from 'antd'
import { history } from 'index'
import { useEffect } from 'react'
import { db, firebaseAuth, currentAccount } from 'services/firebase'
import { showNotification } from '../../../../../components/notificationNavigator'

const { __ } = require('components/ChangeLanguage').getMessage('settings/whatsapp')

const useSessionCheck = (sessionType, getSessionMessage) => {
  useEffect(() => {
    let snapshotListener = () => {}
    let intervalId
    const unsubscribeAuth = firebaseAuth.onAuthStateChanged(async user => {
      if (user) {
        await new Promise(resolve => setTimeout(resolve, 5000))

        const connectionList = {}
        const notifiedSessions = new Set() // Conjunto para armazenar sessões já notificadas
        const delayTime = sessionType === 'whatsapp-sessions' ? 30000 : 1000

        if (sessionType === 'linkedin-sessions') {
          intervalId = setInterval(() => {
            for (const session of Object.values(connectionList)) {
              if (
                !session.data.isConnected &&
                session.checkDelay &&
                Date.now() > session.checkDelay
              ) {
                const key = Date.now()

                // Verifica se já notificou esta sessão
                if (!notifiedSessions.has(session.__id)) {
                  notification.warning({
                    key,
                    duration: 5,
                    message: getSessionMessage(session.data),
                    onClick: () => {
                      history.push('/settings')
                      notification.close(key)
                    },
                  })
                  notifiedSessions.add(session.__id) // Adiciona ao conjunto
                }
                delete session.checkDelay
              }
            }
          }, 1000)
        }

        snapshotListener = db
          .collection('users')
          .doc(user.uid)
          .collection(sessionType)
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              const session = doc.data()
              const sessionId = doc.id

              if (sessionType === 'whatsapp-sessions') {
                // Verifica se o nome da sessão começa com "Nova sessão WhatsApp"
                const newSession = session.name && session.name.startsWith('Nova sessão WhatsApp')

                if (
                  session.name &&
                  session.phone &&
                  session.uuid &&
                  !session.isLoading &&
                  (!session.loggingStatus || session.loggingStatus === 'browserClose') &&
                  !session.isConnected &&
                  !newSession
                ) {
                  const key = Date.now()

                  // Verifica se já notificou esta sessão
                  if (!notifiedSessions.has(sessionId)) {
                    notification.warning({
                      key,
                      duration: 5,
                      message: getSessionMessage(session),
                      onClick: () => {
                        history.push('/settings')
                        notification.close(key)
                      },
                    })
                    notifiedSessions.add(sessionId) // Adiciona ao conjunto

                    if (
                      user.uid === 'caCtn17WKKelKRO0AvitQDoseO92' ||
                      user.uid === 'zMNwtqYrNggh1KoGgmV6OiQa4xF3' ||
                      user.uid === '2wg4PEFutZWVW20kTRAvbYTkq7n2' ||
                      user.owner === 'zMNwtqYrNggh1KoGgmV6OiQa4xF3' ||
                      user.owner === '2wg4PEFutZWVW20kTRAvbYTkq7n2'
                    ) {
                      // Chama a notificação para desconexão do WhatsApp
                      const message = __(
                        'Bot {{0}} is disconnected. Click here to reconnect',
                        session.name,
                      )
                      showNotification('Conexão Perdida', message, '/settings')
                    }
                  }
                }
              } else if (sessionType === 'linkedin-sessions') {
                let item = connectionList[sessionId]

                if (item) {
                  if (!session.isConnected && item.data.isConnected !== session.isConnected) {
                    item.checkDelay = Date.now() + 1000
                  }
                  item.data = session
                } else {
                  connectionList[sessionId] = {
                    __id: sessionId,
                    data: session,
                    checkDelay: Date.now() + 10000 * Math.random(),
                  }

                  doc.ref.update({ checkingSession: true })
                }
              }
            })
          })
      } else {
        snapshotListener?.()
      }
    })

    return () => {
      snapshotListener?.()
      unsubscribeAuth()
      if (intervalId) clearInterval(intervalId)
    }
  }, [sessionType, getSessionMessage])
}

const ConnectionCheck = () => {
  useSessionCheck('whatsapp-sessions', session =>
    __('Bot {{0}} is disconnected. Click here to reconnect', session.name),
  )

  useSessionCheck('linkedin-sessions', session =>
    __(
      'Linkedin bot {{0}} is disconnected. Click here to reconnect',
      session.name || session.linkedin || session.uuid,
    ),
  )

  return null
}

export default ConnectionCheck
